import React, { Fragment } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/LineIcons.2.0.css";
import "./assets/css/animate.css";
import "./assets/css/tiny-slider.css";
import "./assets/css/glightbox.min.css";
import "./assets/css/main.css";
import Web from "../src/components/web"
import Mobile from "../src/components/mobile"
import { isMobile } from 'react-device-detect';


function App() {
  return (
    <Fragment>
       {isMobile ? <Mobile /> : <Web />}
    </Fragment>
  );
}

export default App;

import React, { Fragment, useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import OutReach from "./OutReach";
import Streamlined from "./Streamlined";
import CostReduction from "./CostReduction";
import Footer from "./Footer";
import outreach from "../../assets/images/hero/shield-purple.gif";
import streamlined from "../../assets/images/hero/shield_orange.gif";
import reduction from "../../assets/images/hero/shield_green.gif";

function Mobile() {
  const [outreachHide, setOutreachHide] = useState(0);
  const [streamlinedHide, setStreamlinedHide] = useState(0);
  const [reductionHide, setReductionHide] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const viewportHeight = window.innerHeight;

    const sectionVisibility = (sectionId, setSectionHide) => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const sectionRect = sectionElement.getBoundingClientRect();
        // Check if the bottom of the next section has entered the viewport
        if (sectionRect.top <= viewportHeight && sectionRect.bottom >= 0) {
          setSectionHide(0); // Fade-in current section
        } else if (sectionRect.bottom < 0) {
          setSectionHide(1); // Fade-out current section when the next section comes into view
        }
      }
    };

    sectionVisibility("outreach", setOutreachHide);
    sectionVisibility("streamlined", setStreamlinedHide);
    sectionVisibility("reduction", setReductionHide);
    const tabs = document.querySelector(".tabs");
    if (tabs) {
      const tabsRect = tabs.getBoundingClientRect();
      const shouldBeSticky = tabsRect.top <= 0; // Check if tabs are at the top
      if (shouldBeSticky !== isSticky) {
        setIsSticky(shouldBeSticky); // Only update if the sticky state changes
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Fragment>
      {/* Tabs structure */}

      {/* Sections */}
      <HeroSection />
      <div className={`tabs sticky`}>
        {/* ------------------Outreach-------------- */}
        <div
          id="outreach-tab"
          className={`tab outreach ${
            outreachHide === 1 ? "fade-out" : "fade-in"
          }`}
        >
          <div>
          <img src={outreach} className="tab-gif" />
            <h5>Outreach</h5>
          </div>
        </div>
        {/* --------------------Streamlined---------------- */}
        <div
          id="streamlined-tab"
          className={`tab streamlined ${
            streamlinedHide === 1 ? "fade-out" : "fade-in"
          } ${outreachHide === 1 ? "remove-stream-tab-margin" : ""}`}
        >
          <div>
          <img src={streamlined} className="tab-gif" />
            <h5>Streamlined</h5>
          </div>
        </div>
        {/* --------------------Cost----------------- */}
        <div
          id="cost-tab"
          className={`tab cost-reduction ${
            reductionHide === 1 ? "fade-out" : "fade-in"
          }  ${outreachHide === 1 ? "less-cost-tab-margin" : ""}
          ${streamlinedHide === 1 ? "remove-cost-tab-margin" : "fade-in"}`}
        >
          <div>
          <img src={reduction} className="tab-gif" />
            <h5>Cost Reduction</h5>
          </div>
        </div>
      </div>
      <OutReach hide={outreachHide} />
      <Streamlined hide={streamlinedHide} />
      <CostReduction hide={reductionHide} />
      <Footer />
    </Fragment>
  );
}

export default Mobile;

// src/components/HeroSection.js
import React, { useEffect, useState } from "react";
import heroImage from "../../assets/images/hero/video.png";
import logo from "../../assets/images/logo/logo.svg";
import handDown from "../../assets/images/hero/handDown.svg";
import arrowImg from "../../assets/images/hero/arrow.png";
import outreach from "../../assets/images/hero/outreach.png";
import streamlined from "../../assets/images/hero/streamlined.png";
import reduction from "../../assets/images/hero/reduction.png";
// import outreachImg from "../../assets/images/outreachImg.png";
import outreachImg from "../../assets/images/outreach-gif-new.gif";
import CountMeIn from "./component/countMeIn";

function OutReach({ ref, otherVisible, setOtherVisible, currentActive, setCurrentActive }) {
  return (
    <section id="outreach" className="outreach-area outreach-section-web" ref={ref}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <h2 className="wow fadeInLeft heading1Other" data-wow-delay=".4s">
                Linkedin outreach automation
              </h2>
              <p className="wow fadeInLeft heading2Other" data-wow-delay=".6s">
                Forget about headhunting on Linkedin manually, that's the thing of the past. Automate your Linkedin outreach to candidates through Alfren HR.
              </p>
              <CountMeIn otherVisible={otherVisible} setOtherVisible={setOtherVisible} currentActive={currentActive} setCurrentActive={setCurrentActive} />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12">
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img src={outreachImg} alt="#" className="automate-reach-gif" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OutReach;

// src/components/HeroSection.js
import React, { Fragment, useState } from "react";
import success from "../../../assets/images/hero/success.gif";
import { submitEmail } from "../../../api/api";
function CountMeIn() {
  const copyTextValue = "https://bit.ly/alfrenhrwaitlist";
  const [show, setShow] = useState(false);
  const [nshow, setNShow] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showContent, setshowContent] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitEmail(email);
      setShowAnimation(true);
      setEmail("");
      setError("");
    } catch (error) {
      console.error("Error:", error?.message);
      setError(error?.message);
    }
  };

  function copyText() {
    console.log("copy", copyTextValue);
    navigator.clipboard
      .writeText(copyTextValue)
      .then(() => {
        console.log("Copied to clipboard:", copyTextValue);
        setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  }

  return (
    <Fragment>
      <div className={`count-me-others py-4 `} style={{minHeight: '108px'}}>
        {!show && (
          <div className="pb-3" onClick={() => setShow(true)}>
            <span
              className="ms-2 pb-2"
              style={{ borderBottom: "1px solid black", color: "black" }}
            >
              Count Me In!{" "}
              <svg
                className="ms-2"
                width="21"
                height="10"
                viewBox="0 0 21 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8858 3.53083C20.275 3.915 20.495 4.44583 20.5 5C20.4983 5.54833 20.285 6.08 19.8992 6.47166L16.6342 9.8725C16.5525 9.9575 16.4433 10 16.3333 10C16.2292 10 16.125 9.96167 16.045 9.88333C15.8792 9.725 15.8733 9.46 16.0325 9.29416L19.3017 5.88916C19.4375 5.75166 19.5375 5.59083 19.5975 5.41666H0.916667C0.686667 5.41666 0.5 5.23 0.5 5C0.5 4.77 0.686667 4.58333 0.916667 4.58333H19.5925C19.53 4.41 19.4292 4.25167 19.2942 4.11833L15.9983 0.743332C15.8375 0.579165 15.8408 0.314998 16.0058 0.154165C16.1708 -0.00583534 16.4342 -0.00333549 16.595 0.161665L19.885 3.53083H19.8858Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        )}

        {/* {show && !showAnimation && ( */}
        <div className={`mobile-count-me-in ${show && !showAnimation ? 'mobile-count-me-in-slide' : ''}`}>
            <div className=" ">
              <form onSubmit={handleSubmit}>
                <div className="input-count-me-in">
                  <input
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    placeholder="jhonsmith@email.com"
                    autoComplete="off"
                    className={`input-field ${
                      error ? "input-field-with-error" : ""
                    }`}
                  />
                  {/* {error && */}
                   <p 
                   className={`form-error mobile-count-me-in-error ${error ? 'mobile-count-me-in-error-slide' : ''}`}>
                    {error}</p>
                   {/* } */}
                  <div className="buttonDiv">
                    <button type="submit">Count me In!</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        {/* )} */}

        {/* {showAnimation && ( */}
          <div
            style={{
              backgroundColor: "#F6FFEF",
              borderRadius: "8px",
              padding: "10px",
              border: "1px solid #D7ECC8",
            }}
           className={`mobile-count-me-in-success ${showAnimation ? 'mobile-count-me-in-success-slide' : ''}`}
          >
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-3">
                <img src={success} />
              </div>
              <div className="col-9">
                <div
                  style={{
                    color: "#86B75F",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  Added Successfully
                </div>
                <div
                  style={{
                    color: "#558A2B",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  You will receive an email once this product has launched
                </div>
              </div>
            </div>
            <div className="inviteDivMobile mobile-form-success">
              <div className="title">
                Invite your friends and coworkers to win additional one month
                free
              </div>
              <div className="inputFieldDivMobile">
                <input
                  placeholder="https://bit.ly/alfrenhrwaitlist"
                  name="invite-link"
                  type="text"
                />
                <button
                  className="ms-3"
                  onClick={() => {
                    copyText(true);
                  }}
                >
                  <svg
                    className="me-2"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_438_11201)">
                      <path
                        d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                        stroke={`${copied ? "#1264FD" : "#1D1D1D"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.33337 10H2.66671C2.31309 10 1.97395 9.85953 1.7239 9.60949C1.47385 9.35944 1.33337 9.0203 1.33337 8.66668V2.66668C1.33337 2.31305 1.47385 1.97392 1.7239 1.72387C1.97395 1.47382 2.31309 1.33334 2.66671 1.33334H8.66671C9.02033 1.33334 9.35947 1.47382 9.60952 1.72387C9.85956 1.97392 10 2.31305 10 2.66668V3.33334"
                        stroke={`${copied ? "#1264FD" : "#1D1D1D"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_438_11201">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {!copied ? (
                    <span>Copy Link</span>
                  ) : (
                    <span className="link-copied">Copied</span>
                  )}
                </button>
              </div>
              <div className="message">
                *Copy this link and share with your friends and circle.
              </div>
            </div>
          </div>
         {/* )}  */}
      </div>
    </Fragment>
  );
}

export default CountMeIn;

// src/components/HeroSection.js
import React, { useEffect, useState } from "react";
import costReductionImg from "../../assets/images/costReductionImg-gif.gif";
import CountMeIn from "./component/countMeIn";

function CostReduction({ otherVisible, setOtherVisible, currentActive, setCurrentActive }) {

  return (
    <section id="home" className="outreach-area ">
      <div className="container pt-5 pb-5">
        <div className="row align-items-center ">


          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <h2 className="wow fadeInLeft heading1Other" data-wow-delay=".4s">
                40% Reduction in Cost per Hire
              </h2>
              <p className="wow fadeInLeft heading2Other" data-wow-delay=".6s">
                With everything automated and generating the top results, you can save up to 40% of cost per hire. Now that's some good numbers to boast during quarterly review.
              </p>
              <CountMeIn otherVisible={otherVisible} setOtherVisible={setOtherVisible} currentActive={currentActive} setCurrentActive={setCurrentActive} />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12">
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img src={costReductionImg} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CostReduction;

// src/components/HeroSection.js
import React, { useState } from "react";
// import twitter from "../../assets/images/twitter.png";
import twitter from "../../assets/images/svgs/twitter.svg";
// import facebook from "../../assets/images/facebook.png";
import facebook from "../../assets/images/svgs/facebook.svg";
// import linkedin from "../../assets/images/linkedin.png";
import linkedin from "../../assets/images/svgs/linkedin.svg";
import instagram from "../../assets/images/instagram.png";
import topImg from "../../assets/images/topImg.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";


function Footer({ show }) {
  const url = "https://bit.ly/alfrenhrwaitlist";

  const [copied, setCopied] = useState(false)

  function copyText() {
    console.log("copy", url);
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("Copied to clipboard:", url);
        setCopied(true)
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  }

  return (
    <section id="home" className="outreach-area">
      <div
        style={{ textAlign: "center", padding: "20px" }}
        className="mobile-footer"
      >
        <div className="inviteDivWeb">
          <div className="title">Invite your friends and coworkers.</div>
          <div className="message">
            *copy this link and share with your friends and circle.
          </div>
          <div className="copy-link" onClick={() =>copyText(true)}>
            <svg
              className="me-2"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_438_11201)">
                <path
                  d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                  stroke="#1264FD"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.33337 10H2.66671C2.31309 10 1.97395 9.85953 1.7239 9.60949C1.47385 9.35944 1.33337 9.0203 1.33337 8.66668V2.66668C1.33337 2.31305 1.47385 1.97392 1.7239 1.72387C1.97395 1.47382 2.31309 1.33334 2.66671 1.33334H8.66671C9.02033 1.33334 9.35947 1.47382 9.60952 1.72387C9.85956 1.97392 10 2.31305 10 2.66668V3.33334"
                  stroke="#1264FD"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_438_11201">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            {!copied ? <span>Copy Link</span> : <span>Copied</span>}
          </div>
        </div>

        <div className="footer-center">
          <div className="or-holder">
            <div className="upperline"></div>

            <span className="or">OR</span>
            <div className="lowerline"></div>
          </div>
        </div>

        <div>
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "30px",
              textAlign: "center",
              color: "#0B1425",
            }}
            className="mt-4 mb-3"
          >
            Share on
          </p>
          <div className="text-center mt-4 mb-3">
          <TwitterShareButton url={url}>
              <img src={twitter} className="me-2 ms-2" />
            </TwitterShareButton>

            <LinkedinShareButton url={url}>
              <img src={linkedin} className="me-2 ms-2" />
            </LinkedinShareButton>

            <FacebookShareButton url={url}>
              <img src={facebook} className="me-2 ms-2" />
            </FacebookShareButton>
          </div>
        </div>
        {show && (
          <a
            href="#home"
            style={{ position: "fixed", bottom: "45px", right: "35px" }}
          >
            <img src={topImg} className="me-2 ms-2" width={35} />
          </a>
        )}
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "#FECF41",
          padding: "9px",
        }}
      >
        <span style={{ color: "#1D1D1D" }}>© 2024, All Rights Reserved</span>
      </div>
    </section>
  );
}

export default Footer;

// src/components/HeroSection.js
import React, { useEffect, useState } from "react";

// import streamlinedImg from "../../assets/images/streamlinedImg.png";
import streamlinedImg from "../../assets/images/streamlinedImg-gif.gif";
import CountMeIn from "./component/countMeIn";

function Streamlined({ otherVisible, setOtherVisible, currentActive, setCurrentActive }) {
  return (
    <section id="home" className="outreach-area  " style={{ backgroundColor: "#FFFCF2" }}>
      <div className="container pt-5 pb-5">
        <div className="row align-items-center ">

          <div className="col-lg-7 col-md-12 col-12">
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img src={streamlinedImg} alt="#" />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <h2 className="wow fadeInLeft heading1Other" data-wow-delay=".4s">
                A Streamlined Recruitment Process
              </h2>
              <p className="wow fadeInLeft heading2Other" data-wow-delay=".6s">
                EViewing hundreds of profiles, shortlisting top candidates, sending connection requests, and messages. Sit back and relax, let Alfren HR do all of the daunting stuff.
              </p>
              <CountMeIn otherVisible={otherVisible} setOtherVisible={setOtherVisible} currentActive={currentActive} setCurrentActive={setCurrentActive} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Streamlined;

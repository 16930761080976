// src/components/HeroSection.js
import React, { useEffect, useState } from "react";
// import costReductionImg from "../../assets/images/costReductionImgMobile.png";
import costReductionImg from "../../assets/images/costReductionImg-gif.gif";
import CountMeIn from "./component/countMeIn";

function CostReduction() {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const nextSection = document.getElementById("reduction"); // Replace "nextSection" with the ID of the next section
      const nextSectionTop = nextSection.getBoundingClientRect().top;
      setIsSticky(nextSectionTop <= 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section id="reduction" className="outreach-area  p-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <h2 className={`wow fadeInLeft heading1OtherMobile ${isSticky ? "" : ""}`} data-wow-delay=".4s">
                40% Reduction in Cost per Hire
              </h2>
              <p
                className="wow fadeInLeft heading2OtherMobile"
                data-wow-delay=".6s"
              >
                With everything automated and generating the top results, you can save up to 40% of cost per hire. Now that's some good numbers to boast during quarterly review.
              </p>
              <CountMeIn />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12">
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img src={costReductionImg} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CostReduction;

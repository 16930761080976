import React, { Fragment } from "react";
import logo from "../../assets/images/logo/logo.svg"
import { Helmet } from 'react-helmet';

function Header({ show }) {
  return (
    <Fragment>
      <div className="header navbar-area sticky slide-down" style={{ display: show ? 'block' : 'none', }}>
        <Helmet>
          <meta name="title" content="Alfren HR - The best recruiter among Linkedin automation tools." />
          <meta name="description" content="If you are looking for free linkedin automation tools then, join the waitlist for Alfren HR and get 3 months free. If you are an individual recruiter or a recruitment agency, Alfren HR will help you save time and money by automating your executive search and headhunting​ on Linkedin." />
        </Helmet>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand mt-2 mb-2" href="#">
                    <img src={logo} alt="Logo" />
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
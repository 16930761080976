// src/components/HeroSection.js
import React, { useEffect, useState } from "react";
import outreachImg from "../../assets/images/mobile/outreach-gif.gif";
import CountMeIn from "./component/countMeIn";

function OutReach({ hide }) {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const nextSection = document.getElementById("outreach"); // Replace "nextSection" with the ID of the next section
      const nextSectionTop = nextSection.getBoundingClientRect().top;
      setIsSticky(nextSectionTop <= 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section id="outreach" className={`outreach-area mt-5 p-4 `}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <h2 className={`wow fadeInLeft heading1OtherMobile ${isSticky ? "" : ""}`} data-wow-delay=".4s">
                Automate Outreach
              </h2>
              <p className="wow fadeInLeft heading2OtherMobile " data-wow-delay=".6s">
                Forget about headhunting on Linkedin manually, that's the thing of the past. Automate your Linkedin outreach to candidates through Alfren HR.
              </p>
              <CountMeIn />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12">
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img src={outreachImg} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OutReach;

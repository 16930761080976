import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Use environment variable for base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export const submitEmail = async (email) => {
  try {
    const response = await axiosInstance.post("/v1/waitlist/sendEmail", {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("Error submitting email:", error);
    throw error?.response?.data;
  }
};

// src/components/HeroSection.js
import React, { Fragment, useEffect, useState } from "react";
import success from "../../../assets/images/hero/success.gif";
import { submitEmail } from "../../../api/api";

function CountMeIn({
  otherVisible,
  setOtherVisible,
  currentActive,
  setCurrentActive,
}) {
  const [show, setShow] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setOtherVisible(!showAnimation);
  }, [showAnimation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitEmail(email);
      setShowAnimation(true);
      setEmail("");
      setError("");
    } catch (error) {
      console.error("Error:", error?.message);
      setError(error?.message);
    }
  };

  return (
    <Fragment>
      {otherVisible && (
        <div className={`count-me-others pt-4`} style={{minHeight: '96px'}}>
          {!show && (
            <div
              className="pb-3 pt-3"
              onClick={() => setShow(true)}
              style={{ cursor: "pointer" }}
            >
              <span
                className="ms-2 pb-2"
                style={{ borderBottom: "1px solid black", color: "black" }}
              >
                Count Me In!{" "}
                <svg
                  className="ms-2"
                  width="21"
                  height="10"
                  viewBox="0 0 21 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.8858 3.53083C20.275 3.915 20.495 4.44583 20.5 5C20.4983 5.54833 20.285 6.08 19.8992 6.47166L16.6342 9.8725C16.5525 9.9575 16.4433 10 16.3333 10C16.2292 10 16.125 9.96167 16.045 9.88333C15.8792 9.725 15.8733 9.46 16.0325 9.29416L19.3017 5.88916C19.4375 5.75166 19.5375 5.59083 19.5975 5.41666H0.916667C0.686667 5.41666 0.5 5.23 0.5 5C0.5 4.77 0.686667 4.58333 0.916667 4.58333H19.5925C19.53 4.41 19.4292 4.25167 19.2942 4.11833L15.9983 0.743332C15.8375 0.579165 15.8408 0.314998 16.0058 0.154165C16.1708 -0.00583534 16.4342 -0.00333549 16.595 0.161665L19.885 3.53083H19.8858Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          )}

          {show && !showAnimation && (
            <div className="outer-div-slide-in">
              <div className="slide-in">
                <form onSubmit={handleSubmit} className="">
                  <div className="web-count-me-in">
                    <div>

                    <input
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      placeholder="jhonsmith@email.com"
                      autoComplete="off"
                      className={`web-input-field ${
                        error ? "input-field-with-error" : ""
                      }`}
                      />
                    {/* {error && <p className="form-error">{error}</p>} */}
                    <p 
                   className={`form-error mobile-count-me-in-error ${error ? 'mobile-count-me-in-error-slide' : ''}`}>
                    {error}</p>
                    {!error && <p style={{height:'16px'}}></p>}
                      </div>
                      <button type="submit">Count me In!</button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {showAnimation && (
        <div
          style={{
            backgroundColor: "#F6FFEF",
            borderRadius: "8px",
            border: "1px solid #D7ECC8",
          }}
          className="slide-in"
        >
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-3">
              <img src={success} />
            </div>
            <div className="col-9">
              <div
                style={{
                  color: "#86B75F",
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                Added Successfully
              </div>
              <div
                style={{
                  color: "#558A2B",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                You will receive an email once this product has launched
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default CountMeIn;

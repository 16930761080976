// src/components/HeroSection.js
import React, { useEffect, useState } from "react";
import heroImage from "../../assets/images/hero/video.png";
import logo from "../../assets/images/logo/logo.png";
import handDown from "../../assets/images/hero/handDown.svg";
import arrowImg from "../../assets/images/hero/arrow.png";
import outreach from "../../assets/images/hero/shield-purple.gif";
import streamlined from "../../assets/images/hero/shield_orange.gif";
import reduction from "../../assets/images/hero/shield_green.gif";
import yellow_check from "../../assets/images/hero/yellow-check.svg";
import success from "../../assets/images/hero/success.gif";
// import arrowAnimation from "../../assets/images/hero/arrowAnimationWeb.gif";
import arrowAnimation from "../../assets/images/hero/arrow_r.gif";
import sampleVideo from "../../assets/images/hero/intro.mp4";
import { useInView } from "react-intersection-observer";
import { submitEmail } from "../../api/api";

function HeroSection() {
  const copyTextValue = "https://bit.ly/alfrenhrwaitlist";
  const [error, setError] = useState("")

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);


  // Additional states to track image loading
  const [imageLoadedFirst, setImageLoadedFirst] = useState(false);
  const [imageLoadedSecond, setImageLoadedSecond] = useState(false);
  const [imageLoadedThird, setImageLoadedThird] = useState(false);


  useEffect(() => {
    // First section
    setTimeout(() => {
      setShowFirst(true);
      // setTimeout(() => setTypingEffect1(true), 2500); // Start typing after GIF plays
    }, 500); // Delay before first section appears

    // Second section
    setTimeout(() => {
      setShowSecond(true);
      // setTimeout(() => setTypingEffect2(true), 2500);
    }, 2500); // Delay before second section appears

    // Third section
    setTimeout(() => {
      setShowThird(true);
      // setTimeout(() => setTypingEffect3(true), 2500);
    }, 5000); // Delay before third section appears
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true, // Load the video once when in view
    threshold: 0.5, // Load when 50% of the video is in view
  });

  const handleSignUpClick = async (e) => {
    e.preventDefault();
    try {
      await submitEmail(email);
      setSubmitted(true);
      setEmail("");
      setError("");
    } catch (error) {
      console.error("Error:", error?.message);
      setError(error?.message)
    }
  };

  return (
    <section id="home" className="hero-area herobg">
      <div className="container lineSection">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="circle"></div>
          <div className="circle2"></div>
        </div>
        <div className="text-center mb-5">
          <img src={logo} alt="#" width="150" />
        </div>
        {/* old section starting  */}
        {/* <div className="row mt-5">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <p className="wow fadeInLeft heading1" data-wow-delay=".4s">
                Alfren HR, your personal Linkedin recruiter.
                <span className="heading2 ms-2">
                  Talent Acquisition On Autopilot.
                </span>
              </p>

              {submitted ? (
                <>
                  <div
                    className="slide-in"
                    style={{
                      backgroundColor: "#F6FFEF",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-3">
                        <img src={success} />
                      </div>
                      <div className="col-9">
                        <div
                          style={{
                            color: "#86B75F",
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          Added Successfully
                        </div>
                        <div
                          style={{
                            color: "#558A2B",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          You will receive an email once this product has
                          launched
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="button wow fadeInLeft count-me-div"
                    data-wow-delay=".8s"
                  >
                    <div className="count-me px-5 py-4">
                      <div>
                        <img src={handDown} alt="#" />
                        <span className="ms-2">Count Me In!</span>
                      </div>
                      <div className="">
                        <form
                          className="mt-3 inputDiv"
                          onSubmit={handleSignUpClick}
                        >
                          <input
                            placeholder="jhonsmith@email.com"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                            autoComplete="off"
                          />
                          <div className="buttonDiv">
                            <button type="submit">
                              Sign Me Up
                              <svg
                                className="ms-2"
                                width="21"
                                height="10"
                                viewBox="0 0 21 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.8858 3.53083C20.275 3.915 20.495 4.44583 20.5 5C20.4983 5.54833 20.285 6.08 19.8992 6.47166L16.6342 9.8725C16.5525 9.9575 16.4433 10 16.3333 10C16.2292 10 16.125 9.96167 16.045 9.88333C15.8792 9.725 15.8733 9.46 16.0325 9.29416L19.3017 5.88916C19.4375 5.75166 19.5375 5.59083 19.5975 5.41666H0.916667C0.686667 5.41666 0.5 5.23 0.5 5C0.5 4.77 0.686667 4.58333 0.916667 4.58333H19.5925C19.53 4.41 19.4292 4.25167 19.2942 4.11833L15.9983 0.743332C15.8375 0.579165 15.8408 0.314998 16.0058 0.154165C16.1708 -0.00583534 16.4342 -0.00333549 16.595 0.161665L19.885 3.53083H19.8858Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>{" "}
                  </div>
                </>
              )}

              <div className="signup-div mt-4">
                <p>Sign Up Today To Get:</p>
                <p className="ms-2">
                  <span className="me-3">Early Access</span>
                  <span className="gradientStyleHorizontal"></span>
                  <span className="ms-3">3 Month Free</span>
                </p>
                {!submitted && (
                  <div className="arrowDiv">
                    <img
                      src={arrowAnimation}
                      alt="#"
                      style={{ transform: "rotate(126deg)" }}
                    />
                  </div>
                )}
              </div>

              <div className="inviteDivWeb">
                <div className="title">Invite Your Friends /Coworkers</div>
                <div className="inputFieldDiv">
                  <input
                    defaultValue={copyTextValue}
                    name="invite-link"
                    type="text"
                    className="ms-2"
                  />
                  <button
                    onClick={() => {
                      copyText();
                    }}
                    className="ms-4"
                  >
                    <svg
                      className="me-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_438_11201)">
                        <path
                          d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                          stroke="#1D1D1D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.33337 10H2.66671C2.31309 10 1.97395 9.85953 1.7239 9.60949C1.47385 9.35944 1.33337 9.0203 1.33337 8.66668V2.66668C1.33337 2.31305 1.47385 1.97392 1.7239 1.72387C1.97395 1.47382 2.31309 1.33334 2.66671 1.33334H8.66671C9.02033 1.33334 9.35947 1.47382 9.60952 1.72387C9.85956 1.97392 10 2.31305 10 2.66668V3.33334"
                          stroke="#1D1D1D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_438_11201">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
                <div className="message">
                  *copy this link and share with your friends and circle
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-12 col-12"></div>
          <div className="col-lg-7 col-md-12 col-12">
            <div
              className="hero-image wow fadeInRight mt-4"
              data-wow-delay=".4s"
            >
              <div ref={ref} >
                {inView && (
                  <video
                    width="100%"
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{borderRadius:'12px',
                    boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.1)'}}
                  >
                    <source src={sampleVideo} type="video/mp4" />
                    Your browser does not support the video.
                  </video>
                )}
              </div>
            </div>
          </div>
        </div> */}
        {/* old section ending  */}
        {/* new section starting*/}
        <div className="desktop-hero-section">
          <div className="desktop-hero-left-section-new">
            <h1 className="talent-heading">Talent Acquisition On</h1>
            <div className="auto-pilot">Autopilot.</div>
            <h2 className="linkedin-recruiter">
              Your personal <span className="linkedin-title">LinkedIn</span>{" "}
              recruiter.
            </h2>

            <div className="content-holder">
              {submitted ? (
                <>
                  <div
                    className="slide-in"
                    style={{
                      backgroundColor: "#F6FFEF",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-3">
                        <img src={success} />
                      </div>
                      <div className="col-9">
                        <div
                          style={{
                            color: "#86B75F",
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          Added Successfully
                        </div>
                        <div
                          style={{
                            color: "#558A2B",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          You will receive an email once this product has
                          launched
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <form onSubmit={handleSignUpClick}>
                  <div className="field-holder">
                    <div>

                    <input
                      placeholder="jhonsmith@email.com"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required={true}
                      autoComplete="off"
                      className={`input-feild ${error ? 'input-field-with-error' : ''}`}
                      />
                    {error && <p className="form-error">{error}</p>}
                      </div>
                       
                    <button type="submit">Count me In!</button>
                  </div>
                </form>
              )}
            </div>

            {!error ?
            <div style={{height:'25px'}}></div> 
            :
            <div style={{height:'7px'}}></div> 
            }

            <div className="signup-benifits">
              <h5>Sign Up Today To Get:</h5>
              <div className="benifits-list">
                <div>
                  <img src={yellow_check} alt="" /> <h6>Early Access</h6>
                </div>
                <div>
                  <img src={yellow_check} alt="" /> <h6>3 Month Free</h6>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="desktop-hero-left-section">
            <div className="hero-content">
              <p className="wow fadeInLeft heading1" data-wow-delay=".4s">
               Alfren HR, your personal Linkedin recruiter.
                <span className="heading2 ms-2">
                  Talent Acquisition On Autopilot.
                </span>
              </p>

              {submitted ? (
                <>
                  <div
                    className="slide-in"
                    style={{
                      backgroundColor: "#F6FFEF",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-3">
                        <img src={success} />
                      </div>
                      <div className="col-9">
                        <div
                          style={{
                            color: "#86B75F",
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          Added Successfully
                        </div>
                        <div
                          style={{
                            color: "#558A2B",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          You will receive an email once this product has
                          launched
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="button wow fadeInLeft count-me-div"
                    data-wow-delay=".8s"
                  >
                    <div className="count-me px-5 py-4">
                      <div>
                        <img src={handDown} alt="#" />
                        <span className="ms-2">Count Me In!</span>
                      </div>
                      <div className="">
                        <form
                          className="mt-3 inputDiv"
                          onSubmit={handleSignUpClick}
                        >
                          <input
                            placeholder="jhonsmith@email.com"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                            autoComplete="off"
                          />
                          <div className="buttonDiv">
                            <button type="submit">
                              Sign Me Up
                              <svg
                                className="ms-2"
                                width="21"
                                height="10"
                                viewBox="0 0 21 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19.8858 3.53083C20.275 3.915 20.495 4.44583 20.5 5C20.4983 5.54833 20.285 6.08 19.8992 6.47166L16.6342 9.8725C16.5525 9.9575 16.4433 10 16.3333 10C16.2292 10 16.125 9.96167 16.045 9.88333C15.8792 9.725 15.8733 9.46 16.0325 9.29416L19.3017 5.88916C19.4375 5.75166 19.5375 5.59083 19.5975 5.41666H0.916667C0.686667 5.41666 0.5 5.23 0.5 5C0.5 4.77 0.686667 4.58333 0.916667 4.58333H19.5925C19.53 4.41 19.4292 4.25167 19.2942 4.11833L15.9983 0.743332C15.8375 0.579165 15.8408 0.314998 16.0058 0.154165C16.1708 -0.00583534 16.4342 -0.00333549 16.595 0.161665L19.885 3.53083H19.8858Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>{" "}
                  </div>
                </>
              )}

              <div className="signup-div mt-4">
                <p>Sign Up Today To Get:</p>
                <p className="ms-2">
                  <span className="me-3">Early Access</span>
                  <span className="gradientStyleHorizontal"></span>
                  <span className="ms-3">3 Month Free</span>
                </p>
                {!submitted && (
                  <div className="arrowDiv desktop-arrow-div">
                    <img
                      src={arrowAnimation}
                      alt="#"
                      className="desktop-arrow-animation"
                      // style={{ transform: "rotate(126deg)" }}
                    />
                  </div>
                )}
              </div>

              <div className="inviteDivWeb">
                <div className="title">Invite Your Friends /Coworkers</div>
                <div className="inputFieldDiv">
                  <input
                    defaultValue={copyTextValue}
                    name="invite-link"
                    type="text"
                    className="ms-2"
                  />
                  <button
                    onClick={() => {
                      copyText();
                    }}
                    className="ms-4"
                  >
                    <svg
                      className="me-2"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_438_11201)">
                        <path
                          d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                          stroke="#1D1D1D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.33337 10H2.66671C2.31309 10 1.97395 9.85953 1.7239 9.60949C1.47385 9.35944 1.33337 9.0203 1.33337 8.66668V2.66668C1.33337 2.31305 1.47385 1.97392 1.7239 1.72387C1.97395 1.47382 2.31309 1.33334 2.66671 1.33334H8.66671C9.02033 1.33334 9.35947 1.47382 9.60952 1.72387C9.85956 1.97392 10 2.31305 10 2.66668V3.33334"
                          stroke="#1D1D1D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_438_11201">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
                <div className="message">
                  *copy this link and share with your friends and circle
                </div>
              </div>
            </div>
          </div> */}

          <div className="desktop-hero-right-section">
            <div
              className="hero-image wow fadeInRight mt-4"
              data-wow-delay=".4s"
            >
              <div ref={ref}>
                {inView && (
                  <video
                    width="100%"
                    // autoPlay
                    loop
                    // muted
                    playsInline
                    controls
                    className="intro-video"
                  >
                    <source src={sampleVideo} type="video/mp4" />
                    Your browser does not support the video.
                  </video>
                )}
              </div>
              {/* <img src={heroImage} alt="#" /> */}
              {/* <video width="100%" autoPlay muted loop className="rounded-1">
                  <source src={sampleVideo} type="video/mp4" />
                  <source src={sampleVideo} type="video/ogg" />
                  Your browser does not support the video.
                </video> */}
            </div>
          </div>
        </div>
        {/* new section ending*/}
        {/* ------------------------------------------------------------------------------------ */}
        <div className="propertiesDiv">
          <div className="row">
            {showFirst && (
              <h3 className="col-4" style={{ position: "relative" }}>
                <span className="me-2">
                  <img src={outreach} className="gif" onLoad={() => setImageLoadedFirst(true)}  />
                </span>
                <span className="outreach-span animated-span"></span>
                 {imageLoadedFirst && <span className="prop-fade-in">Linkedin Outreach Automation​</span>} 
              </h3>
            )}

            {showSecond && (
              <h3 className="col-4" style={{ position: "relative" }}>
                <span className="me-2">
                  <img src={streamlined} className="gif" onLoad={() => setImageLoadedSecond(true)} />
                </span>
                <span className="streamlined-span animated-span"></span>
                {imageLoadedSecond && <span className="prop-fade-in">A Streamlined Recruitment Process</span>} 
              </h3>
            )}
            {showThird && (
              <h3 className="col-4" style={{ position: "relative" }}>
                <span className="me-2">
                  <img src={reduction} className="gif" onLoad={() => setImageLoadedThird(true)}/>
                </span>
                <span className="reduction-span animated-span"></span>
                 {imageLoadedThird && <span className="prop-fade-in">40% Reduction in Cost per Hire</span>}
              </h3>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

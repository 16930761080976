// src/components/HeroSection.js
import React, { useEffect, useState } from "react";

import streamlinedImg from "../../assets/images/streamlinedImg-gif.gif";
import CountMeIn from "./component/countMeIn";

function Streamlined() {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const nextSection = document.getElementById("streamlined"); // Replace "nextSection" with the ID of the next section
      const nextSectionTop = nextSection.getBoundingClientRect().top;
      setIsSticky(nextSectionTop <= 0);

    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section
      id="streamlined"
      className="outreach-area mt-5 p-4"
      style={{ backgroundColor: "#FFFCF2" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-12">
            <div className="hero-content">
              <h2
                className={`wow fadeInLeft heading1OtherMobile ${isSticky ? "" : ""}`}
                style={{ backgroundColor: "#FFFCF2" }}
                data-wow-delay=".4s"
              >
                A Streamlined Recruitment Process
              </h2>
              <p
                className="wow fadeInLeft heading2OtherMobile"
                data-wow-delay=".6s"
              >
                Viewing hundreds of profiles, shortlisting top candidates, sending connection requests, and messages. Sit back and relax, let Alfren HR do all of the daunting stuff.
              </p>
              <CountMeIn />

            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-12">
            <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
              <img src={streamlinedImg} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Streamlined;

// src/components/HeroSection.js
import React, { Fragment, useEffect, useState } from "react";
import HeroSection from "./HeroSection";
import Header from "./header";
import OutReach from "./OutReach";
import Streamlined from "./Streamlined";
import Footer from "./Footer";
import CostReduction from "./CostReduction"
import { useInView } from "react-intersection-observer";

function Web() {

  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const handleScroll = () => {
    const outreachElement = document.getElementById('outreach');
    const outreachRect = outreachElement.getBoundingClientRect();
    const isOutreachVisible = (outreachRect.bottom <= window.innerHeight);
    setIsHeaderVisible(isOutreachVisible);
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const [otherVisible, setOtherVisible] = useState(true);
  const [currentActive, setCurrentActive] = useState(true);

  return (
    <Fragment>
    <Header show={isHeaderVisible} />
    <HeroSection />
    <OutReach otherVisible={otherVisible} setOtherVisible={setOtherVisible} currentActive={currentActive} setCurrentActive={setCurrentActive} />
    <Streamlined otherVisible={otherVisible} setOtherVisible={setOtherVisible} currentActive={currentActive} setCurrentActive={setCurrentActive} />
    <CostReduction otherVisible={otherVisible} setOtherVisible={setOtherVisible} currentActive={currentActive} setCurrentActive={setCurrentActive} />
    <Footer  show={isHeaderVisible} />
  </Fragment>
  );
}

export default Web;

// src/components/HeroSection.js
import React, { useState } from "react";
import heroImage from "../../assets/images/hero/video.png";
import logo from "../../assets/images/logo/logo.svg";
import handDown from "../../assets/images/hero/handDown.svg";
import arrowImg from "../../assets/images/hero/arrowMobile.png";
import outreach from "../../assets/images/hero/outreach.png";
import streamlined from "../../assets/images/hero/streamlined.png";
import reduction from "../../assets/images/hero/reduction.png";
import yellow_check from "../../assets/images/hero/yellow-check.svg";
import mobileBg from "../../assets/images/hero/mobilebg.png";
// import arrowAnimation from "../../assets/images/hero/arrowAnimationWeb.gif";
import arrowAnimation from "../../assets/images/hero/arrow_r.gif";
import success from "../../assets/images/hero/success.gif";
import { submitEmail } from "../../api/api";
import { useInView } from "react-intersection-observer";
import sampleVideo from "../../assets/images/hero/intro.mp4";

function HeroSection({ show, outreachHide, streamlinedHide }) {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("")

  const { ref, inView } = useInView({
    triggerOnce: true, // Load the video once when in view
    threshold: 0.5, // Load when 50% of the video is in view
  });

  function copyText() {
    console.log("copy");
  }

  const handleSignUpClick = async (e) => {
    e.preventDefault();
    try {
      await submitEmail(email);
      setSubmitted(true);
      setEmail("");
      setError("")
    } catch (error) {
      console.error("Error:", error?.message);
      setError(error?.message)
    }
  };

  return (
    <section id="home" className="hero-area mobile-bg">
      <div className="container lineSection">
        <div className="linesMobile">
          <div className="lineMobile"></div>
          <div className="lineMobile"></div>
          <div className="lineMobile"></div>
          <div className="lineMobile"></div>
        </div>
        <div className="text-center mb-4">
          <img src={logo} alt="#" width={120} />
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-12" style={{ padding: 0 }}>
            <div className="hero-content mobile-hero-section">
              <p className="wow fadeInLeft heading1Mobile" data-wow-delay=".4s">
                <h1 className="talent-heading">Talent Acquisition On</h1>
                <h1 className="auto-pilot">Autopilot.
                  <div className="bg"></div>
                </h1>
                <h2 className="linkedin-recruiter">
                  Your personal <span className="linkedin-title">LinkedIn</span>{" "}
                  recruiter.
                </h2>
              </p>
              {submitted ? (
                <>
                  <div
                    className="slide-in mt-2"
                    style={{
                      backgroundColor: "#F6FFEF",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <div className="col-3">
                        <img src={success} />
                      </div>
                      <div className="col-9">
                        <div
                          style={{
                            color: "#86B75F",
                            fontSize: "18px",
                            fontWeight: 700,
                          }}
                        >
                          Added Successfully
                        </div>
                        <div
                          style={{
                            color: "#558A2B",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          You will receive an email once this product has
                          launched
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="button wow fadeInLeft mt-6"
                    data-wow-delay=".8s"
                  >
                    <div className="inputDivMobile mobile-form-holder">
                      <form onSubmit={handleSignUpClick}>
                        <div className="">
                          <input
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                            placeholder="jhonsmith@email.com"
                            autoComplete="off"
                            className={`input-feild ${error ? 'input-field-with-error' : ''}`}
                          />
                          {error && <p className="form-error">{error}</p>}
                        </div>
                        <div className="button-holder">
                          <button type="submit" className="submit-button">
                            Count me In!
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}

              <div className="signup-div-mobile">
                  <h5>Sign Up Today To Get:</h5>
                  <div className="benifits-list">
                    <div>
                      <img src={yellow_check} alt="" /> <h6>Early Access</h6>
                    </div>
                    <div>
                      <img src={yellow_check} alt="" /> <h6>3 Month Free</h6>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-12 col-12"></div>
          <div className="col-lg-5 col-md-12 col-12">
            <div
              className="hero-image wow fadeInRight mt-6"
              data-wow-delay=".4s"
            >
              <div ref={ref} className="rounded-1">
                {inView && (
                  <video
                    width="100%"
                    autoPlay
                    loop
                    muted
                    className="rounded-1"
                    playsInline
                  >
                    <source src={sampleVideo} type="video/mp4" />
                    Your browser does not support the video.
                  </video>
                )}
              </div>
              {/* <img src={heroImage} alt="#" /> */}
            </div>
          </div>

          {/* <div className="inviteDivMobile">
            <div className="title">Invite Your Friends /Coworkers</div>
            <div className="inputFieldDivMobile">
              <input
                placeholder="https://levelup.top/EnzZmaterial"
                name="invite-link"
                type="text"
              />
              <button
                className="ms-3"
                onClick={() => {
                  copyText(true);
                }}
              >
                <svg
                  className="me-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_438_11201)">
                    <path
                      d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                      stroke="#1D1D1D"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.33337 10H2.66671C2.31309 10 1.97395 9.85953 1.7239 9.60949C1.47385 9.35944 1.33337 9.0203 1.33337 8.66668V2.66668C1.33337 2.31305 1.47385 1.97392 1.7239 1.72387C1.97395 1.47382 2.31309 1.33334 2.66671 1.33334H8.66671C9.02033 1.33334 9.35947 1.47382 9.60952 1.72387C9.85956 1.97392 10 2.31305 10 2.66668V3.33334"
                      stroke="#1D1D1D"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_438_11201">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div className="message">
              *copy this link and share with your friends and circle
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
